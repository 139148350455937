import React from "react"
import { graphql } from 'gatsby'

import Layout from '../../components/theme/Layout'
import Header from '../../components/theme/Header'
import DiapersPage from '../../components/pages/DiapersPage'

const TermsOfServicePage = ({ data }) => (
  <Layout
    meta={data.defaultPage.meta}
    urls={data.defaultPage.urls}
    locale='en'
  >
    <Header
      urls={data.defaultPage.urls}
      locale='en'
      secondLogo
    />

    <DiapersPage locale='en' />
  </Layout>
)

export const query = graphql`
query diapersPage {
  defaultPage(guid: { eq: "e7532e8e-02be-45bd-922b-0d2f47285bc5" }, locale: {eq:"en"}) {
    title
    subtitle
    description
    urls {
      en
      fr
    }
    meta {
      description
      keywords
      title
    }
  }
}
`

export default TermsOfServicePage
